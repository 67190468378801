import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import PinsComponent from "../components/pins-component"
import SEO from "../components/seo"

const PinsPage = () => (
  <div>
   <SEO title="Cloudpin | Location Based Sharing" description="Get connected. Send & receive content wirelessly."
    author="@cloudpinit"
    url="https://www.cloudpinit.com" image="../images/preview.png" twitterUsername="@cloudpinit"/>
    <PinsComponent></PinsComponent>
  </div>
)

export default PinsPage
