import { Link } from "gatsby"
import React from "react"
import firebase from "./auth/fire-base.js"
import NoImage from "../images/group-28.png"
import FileImage from "../images/group-27.png"
import YoutubeIconImage from "../images/group-29.png"

class BookmarksComponent extends React.Component {
  constructor(props) {
    super(props)
    this.getAuthorName = this.getAuthorName.bind(this)
    this.bookmarksError = React.createRef()
    this.unsubscribe1 = null
    this.unsubscribe2 = null
  }

  state = {
    bookmarks: [],
    pinCreators: [],
    active: false,
    check: [],
    uid: ""
  }

  componentDidMount() {
    this.isLoggedIn()
  }

  componentWillUnmount() {
  if(this.unsubscribe1 !== null)
    this.unsubscribe1()
  if(this.unsubscribe2 !== null)
    this.unsubscribe2()
  }

  loadDefault(id) {
    document.getElementById(id).src = FileImage
    document.getElementById(id).onerror = null
  }

  getBookmarkHtml(id, imageSrc, text, authorName, authorImg, password) {
    var link = "/search?pincode=" + id

    if(text.includes("youtube.com/watch"))
      imageSrc = YoutubeIconImage
    return (
      <div className={this.props.limit ? "col-md-4 col-sm-1" : " col-sm-3 col-6"} key={id} style={this.props.limit ? {} : {padding: "15px"}}>
        <Link to={link} style={{ color: "inherit", textDecoration: "inherit" }}>
          <div className="card nearby-card">
            <img
              id={"image-" + id}
              src={imageSrc ? imageSrc : NoImage}
              className="nearby-img"
              onError={() => this.loadDefault("image-" + id)}
              style={{maxHeight: "130px", minHeight: "130px", }}
              alt={""}
            ></img>
            <div className="nearby-card-body">
              <h5 className="nearby-card-title">{text !== "" && password === "" ? text.substring(0, 15) + "..." : "Protected"}</h5>
              <p className="nearby-card-text">{id}</p>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  getAuthorName(pinID, creator) {
    if (creator) {
      firebase
        .firestore()
        .collection("users")
        .doc(creator.id)
        .get()
        .then(creatorDoc => {
          if (creatorDoc.exists) {
            this.setState(prevState => ({
              pinCreators: {
                ...prevState.pinCreators,
                [pinID]: creatorDoc.data().name,
              },
            }))
          }
        })
    }
  }

  async isLoggedIn() {
    this.setState({ active: false })
    await firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.getBookmarkPincodes()
        this.setState({ active: true, uid: user.uid })
      }
    })
  }

   getBookmarkPincodes() {
    this.bookmarksError.current.hidden = true;
     this.unsubscribe1 = firebase
      .firestore()
      .collection("bookmarks")
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(pinList => {
        if (pinList.exists) {
          var check = []
          if (
            pinList.data().pincodes === undefined ||
            pinList.data().pincodes.length === 0
          ) {
            check = [""]
          } else {
            check = pinList.data().pincodes
          }
          this.setState({ check: check, bookmarks: [] })
          this.getPins()
        }
      })
  }

  getPins() {
  this.setState({bookmarks: []});
  var updateBookmarks = [];
  const {active, check} = this.state
     active
      ? this.unsubscribe2 = firebase
          .firestore()
          .collection("pins")
          .where("pincode", "in", check)
          .orderBy("createdAt", "desc")
          .onSnapshot(snapshot => {
            this.setState({bookmarks: []});
            snapshot.forEach(doc => {
              if (doc && doc.data()) {
                updateBookmarks.push(doc.id);
                this.setState(
                  prevState => {
                    const bookmarks = this.state.bookmarks.concat({
                      id: doc.id,
                      text: doc.data().content.text,
                      creatorID: doc.data().creator
                        ? doc.data().creator.id
                        : null,
                      createdAt: doc.data().createdAt,
                      imageSrc: doc.data().content.file,
                      password: doc.data().password ? doc.data().password : ""
                    })

                    return { bookmarks }
                  },
                  () => this.getAuthorName(doc.id, doc.data().creator)
                )
              }
            })
            if(check.length !== updateBookmarks.length) {
              firebase
                  .firestore()
                  .collection("bookmarks")
                  .doc(this.state.uid)
                  .set({ pincodes: updateBookmarks })
            }
          })
      : (this.bookmarksError.current.hidden = false)
  }

  render() {
    var my_bookmarks =
      this.state.bookmarks.length > 0
        ? this.state.bookmarks.map(pin =>
            this.getBookmarkHtml(
              pin.id,
              pin.imageSrc,
              pin.text,
              this.state.pinCreators[pin.id],
              "",
              pin.password
            )
          )
        : ""
    return (
      <div className="d-flex">
        <div className="" style={{ width: "100%" }}>
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12">
                <h2>Bookmarks</h2>
              </div>
              <p
                id="bookmarksError"
                style={{ color: "red" }}
                hidden
                ref={this.bookmarksError}
              >
                Cannot access logged in user
              </p>
              {my_bookmarks}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BookmarksComponent
