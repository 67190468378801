import { Link } from "gatsby"
import React from "react"
import Sidebar from "react-sidebar"
import DesktopSidebar from "./sidebar"
import { IoMdMenu } from "react-icons/io"
import Header from "./header"
import firebase from "./auth/fire-base.js"
import geohash from "ngeohash"
import BookmarksComponent from "../components/bookmarks-component"
import FileIcon from "../images/file-icon.png"
import NoImage from "../images/group-28.png"
import YoutubeIconImage from "../images/group-29.png"
import NewPinModal from "../components/newpin-modal"

class PinsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
    this.getAuthorName = this.getAuthorName.bind(this)
    this.storeLocation = this.storeLocation.bind(this)
    this.pinsError = React.createRef()
  }

  state = {
    sidebarOpen: false,
    pins: [],
    pinCreators: [],
    lat: null,
    long: null,
    geohash: null,
    active: false,
    isOpen: false,
  }

  componentDidMount() {
    this.isLoggedIn()
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open })
  }

  showModal(open) {
    this.setState({ isOpen: open })
  }

  hideModal() {
    this.setState({ isOpen: false })
  }

  loadDefault(id) {
    document.getElementById(id).src = FileIcon
    document.getElementById(id).onerror = null
  }

  getPinHtml(id, imageSrc, text, authorName, authorImg, password) {
    var link = "/search?pincode=" + id
    if (text.includes("youtube.com/watch")) imageSrc = YoutubeIconImage
    return (
      <div
        className={this.props.limit ? "col-md-4 col-sm-1" : " col-sm-3 col-6"}
        key={id}
        style={this.props.limit ? {} : { padding: "15px" }}
      >
        <Link to={link} style={{ color: "inherit", textDecoration: "inherit" }}>
          <div className="card nearby-card">
            <img
              id={"image-" + id}
              src={imageSrc ? imageSrc : NoImage}
              className="nearby-img"
              onError={() => this.loadDefault("image-" + id)}
              style={{ maxHeight: "130px", minHeight: "130px" }}
              alt={""}
            ></img>
            <div className="nearby-card-body">
              <h5 className="nearby-card-title">
                {text !== "" && password === ""
                  ? text.substring(0, 15) + "..."
                  : "Protected"}
              </h5>
              <p className="nearby-card-text">{id}</p>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  getAuthorName(pinID, creator) {
    if (creator) {
      firebase
        .firestore()
        .collection("users")
        .doc(creator.id)
        .get()
        .then(creatorDoc => {
          if (creatorDoc.exists) {
            this.setState(prevState => ({
              pinCreators: {
                ...prevState.pinCreators,
                [pinID]: creatorDoc.data().name,
              },
            }))
          }
        })
    }
  }

  getLocation() {
    if (!this.state.lat || !this.state.long || !this.state.geohash) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.storeLocation)
      } else {
        alert("Geolocation is not supported by this browser.")
      }
    }
  }

  storeLocation(position) {
    this.setState({
      lat: position.coords.latitude,
      long: position.coords.longitude,
      geohash: geohash.encode(
        position.coords.latitude,
        position.coords.longitude
      ),
    })
  }

  async isLoggedIn() {
    this.setState({ active: false })
    await firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ active: true })
        this.getPins()
      }
    })
  }

  getPins() {
    const { active } = this.state
    this.setState({ pins: [] })
    this.pinsError.current.hidden = true
    active
      ? firebase
          .firestore()
          .collection("pins")
          .where(
            "creator",
            "==",
            firebase
              .firestore()
              .collection("users")
              .doc(firebase.auth().currentUser.uid)
          )
          .orderBy("createdAt", "desc")
          .onSnapshot(snapshot => {
            this.setState({ pins: [] })
            snapshot.forEach(doc => {
              if (doc && doc.data()) {
                this.setState(
                  prevState => {
                    const pins = this.state.pins.concat({
                      id: doc.id,
                      text: doc.data().content.text,
                      creatorID: doc.data().creator
                        ? doc.data().creator.id
                        : null,
                      createdAt: doc.data().createdAt,
                      imageSrc: doc.data().content.file,
                      password: doc.data().password ? doc.data().password : "",
                    })

                    return { pins }
                  },
                  () => this.getAuthorName(doc.id, doc.data().creator)
                )
              }
            })
          })
      : (this.pinsError.current.hidden = false)
  }

  render() {
    var my_pins =
      this.state.pins.length > 0
        ? this.state.pins.map(pin =>
            this.getPinHtml(
              pin.id,
              pin.imageSrc,
              pin.text,
              this.state.pinCreators[pin.id],
              "",
              pin.password
            )
          )
        : ""

    return (
      <Sidebar
        sidebar={<DesktopSidebar> </DesktopSidebar>}
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
      >
        <div className="sidebar-btn">
          <IoMdMenu size={24} onClick={() => this.onSetSidebarOpen(true)} />
        </div>
        <div className="d-flex">
          <div className="desktop-only">
            <DesktopSidebar> </DesktopSidebar>
          </div>
          <div className="" style={{ width: "100%" }}>
            <Header></Header>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12">
                  <h2>My Pins</h2>
                </div>
                <p
                  id="pinsError"
                  style={{ color: "red" }}
                  hidden
                  ref={this.pinsError}
                >
                  Cannot access logged in user
                </p>
                {my_pins}
              </div>
              <div
                className="mobile-add-pin"
                onClick={() => this.showModal(true)}
              >
                +
              </div>
              <NewPinModal
                hideModal={() => this.hideModal()}
                isOpen={this.state.isOpen}
                editMode={false}
              />
            </div>
            <BookmarksComponent></BookmarksComponent>
          </div>
        </div>
      </Sidebar>
    )
  }
}

export default PinsComponent
